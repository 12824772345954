<script>
import get from 'lodash/get';
import head from 'lodash/head';
import { mapActions, mapMutations, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { CommentsList } from '@/components';
import { COMMENTABLE_TYPES } from '@/components/Comments/v1/constants/comments';
import { COMPANY_SCOPES } from '../../store/CompanyModule';

export default {
  name: 'CompanyCommentsView',
  components: {
    CommentsList,
  },
  props: {
    userCompanyUuid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      internalRemarks: null,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
    }),
    ...mapState(DOMAINS_MODEL.app.comments, {
      comments: state => get(state, 'comments.data', []),
      commentsStatus: state => state.comments.STATUS,
    }),
  },
  async created() {
    await this.getCommentsByUuid();
    const { hits } = await this.$algolia.fetchIndex(ALGOLIA_INDEXES.companies, {
      filters: `uuid:${this.company.uuid}`,
    });
    const company = head(hits);

    this.internalRemarks = get(company, 'internal_remarks');
  },
  methods: {
    async getCommentsByUuid() {
      this.clearData();
      await this.getCommentsByType({
        commentableUuid: this.company.uuid,
        commentableType: COMMENTABLE_TYPES.company,
      });
    },
    ...mapActions(DOMAINS_MODEL.app.comments, [
      'getCommentsByType',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.comments, [
      'clearData',
    ]),
  },
};
</script>

<template>
  <div
    class="CompanyCommentsView"
    data-test-id="company_comments-view"
  >
    <div
      v-if="internalRemarks"
      class="mb-4"
    >
      <h1 class="mb-2">
        Internal remarks
      </h1>
      <p data-tesst-id="internal_remarks-content">
        {{ internalRemarks }}
      </p>
    </div>
    <CommentsList
      :comments="comments"
      :is-loading="commentsStatus.LOADING"
      :entity-uuid="company.uuid"
      :callback="getCommentsByUuid"
      entity="company"
    />
  </div>
</template>
