var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CompanyCommentsView",
      attrs: { "data-test-id": "company_comments-view" },
    },
    [
      _vm.internalRemarks
        ? _c("div", { staticClass: "mb-4" }, [
            _c("h1", { staticClass: "mb-2" }, [_vm._v(" Internal remarks ")]),
            _c(
              "p",
              { attrs: { "data-tesst-id": "internal_remarks-content" } },
              [_vm._v(" " + _vm._s(_vm.internalRemarks) + " ")]
            ),
          ])
        : _vm._e(),
      _c("CommentsList", {
        attrs: {
          comments: _vm.comments,
          "is-loading": _vm.commentsStatus.LOADING,
          "entity-uuid": _vm.company.uuid,
          callback: _vm.getCommentsByUuid,
          entity: "company",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }